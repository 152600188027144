<template>
  <div>
    <ViewTemplateSelectOption title="Selecione o tipo de locação" :panel="panel" :options="options"
      :clickedNext="navegateTo" />
  </div>
</template>

<script>
import ViewTemplateSelectOption from "@nixweb/nixloc-ui/src/component/template/ViewTemplateSelectOption";

export default {
  name: "RentalSelectOptionView",
  components: {
    ViewTemplateSelectOption,
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Locação",
        title: "Locação",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
      options: [
        {
          title: "Avulsa",
          titleSize: "17",
          icon: "fas fa-calendar",
          iconSize: 40,
          value: 1,
        },
      /*  {
          title: "Longo Prazo",
          titleSize: "17",
          icon: "fas fa-calendar-alt",
          iconSize: 40,
          value: 2,
        },*/
      ],
    };
  },
  methods: {
    navegateTo(selectedOption) {
      this.$router.push({
        name: "rentCreate",
        params: { type: selectedOption },
      });
    },
  },
};
</script>
